@use 'styles/vapor' as vapor;

$max-page-width: 916px;
$mobile-bottom-padding: 64px;
$tablet-bottom-padding: 120px;
$usage-insights-queries: (
  row:  ( min-width:  610px ),
);

.UsageInsights {
  container: usage-insights / inline-size;
  margin: auto;
  max-width: $max-page-width;
  width: 100%;
  transition: width 0.1s ease-out;

  padding-top: vapor.spacing('extra-loose');
  padding-bottom: $mobile-bottom-padding;

  @include vapor.respond-to('tablet') {
    padding-top: vapor.spacing('extreme-loose');
    padding-bottom: $tablet-bottom-padding;
  }

  .estimates {
    display: flex;
    gap: vapor.spacing('base');
    align-items: stretch;
    flex-direction: column;

    @include vapor.respond-to('tablet') {
      gap: vapor.spacing('base-loose');
    }

    &.row {
      @include vapor.respond-to-container('row', $usage-insights-queries) {
        flex-direction: row;
  
        > * {
          flex-basis: 50%;
        }
      }
    }
  }
}
