@use 'styles/vapor' as vapor;

$base-modal-max-width: 400px;
$large-modal-max-width: 800px;
$modal-max-height: 88vh;

.Modal {
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: vapor.border-radius('base');
  width: 100%;
  margin: vapor.spacing('base');
  background-color: vapor.color('white');
  max-height: $modal-max-height;

  &.widthBase {
    max-width: $base-modal-max-width;
  }

  &.widthLarge {
    max-width: $large-modal-max-width;
  }

  .content {
    display: flex;
    flex: 1;
    overflow-y: auto;

    &.contentFlexDirectionRow {
      flex-direction: row;
    }

    &.contentFlexDirectionColumn {
      flex-direction: column;
    }

    &.hide {
      visibility: hidden;
    }
  }

  .closeButton {
    position: absolute;
    right: vapor.spacing('tight');
    top: vapor.spacing('tight');
  }

  .controls {
    flex: 0 0 auto;
    border-top: 1px solid vapor.color('steel', 'base-15');

    &.hide {
      visibility: hidden;
    }
  }

  .overlay {
    position: absolute;
    z-index: vapor.z-index('overlay');
    top: vapor.spacing('loose');
    right: vapor.spacing('none');
    bottom: vapor.spacing('base-tight');
    left: vapor.spacing('none');
    background-color: vapor.color('white');
    padding-bottom: vapor.spacing('base-semi-loose');
  }
}