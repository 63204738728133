@use 'styles/vapor' as vapor;

$tab-height: 40px;
$tab-border-radius: 50px;

.SlidingTabs {
  display: flex;
}

.tabInput {
  @include vapor.visually-hidden();

  &:checked {
    & + label {
      color: vapor.color('steel', 'base-100');
    }
  }
}

.tabs {
  display: flex;
  position: relative;
  background-color: vapor.color('steel', 'base-5');
  padding: vapor.spacing('extreme-tight');
  border-radius: $tab-border-radius;
  width: 100%;
  * {
    z-index: 2;
  }
}

.tab {
  height: $tab-height;
  border-radius: $tab-border-radius;
}

.tabLabel {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: vapor.color('steel', 'base-30');
  transition: color 0.15s ease-in;
  
  @include vapor.typography('button', 'base');
  text-transform: uppercase;
}

.glider {
  position: absolute;
  display: flex;
  height: $tab-height;
  background-color: vapor.color('white', 'base-100');
  z-index: 1;
  border-radius: $tab-border-radius;
  transition: 0.25s ease-out;
}
