@use 'sass:map';
@use 'sass:meta';

/// Returns container query
///
/// @param {String} $query - Container size in which a change will occur.
/// be sure to add the required 'container' property to the class/component
/// you are querying.
/// 
/// @param {Map} $store - Map of all the available queries for a container.
/// 
/// @param {String} $container - Optional string to specify which container you
/// are referencing, useful if there are multiple. Query responds to closest 
/// parent with the 'container' property if the container is not specified.
///
/// @return {Container Query} content scoped to chosen query
/// 
/// To use this @mixin:
/// 
/// NOTE: DO NOT USE QUOTES AROUND NAME OR QUERIES
/// 
/// $ex-queries: (
///  'base':  ( min-width:  650px ),
///  'large': ( min-width:  850px ),
/// ) 
/// 
/// $ex-name: login-card
/// 
/// .ExampleComponentClass {
///   container: $ex-name / inline-size;
///   <content>
/// }
/// 
/// @include vapor.respond-to-container('base', $ex-queries, $ex-name) {
///   <content>
/// }
/// 
@mixin respond-to-container($query, $store, $container: null) {
  @if map.has-key($store, $query) {
    @container #{$container} #{meta.inspect(map.get($store, $query))} {
      @content;
    }
  }
  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$query}`. "
      + " You must pass in a local store of valid container queries";
  }
}
