@use 'styles/vapor' as vapor;

.InsightsCard {
  box-shadow: vapor.shadow('drop-shadow-2');
  border-radius: vapor.border-radius('extreme-large');
  background-color: vapor.color('white');
  overflow: hidden;

  .header {
    padding: vapor.spacing('base-semi-loose');

    @include vapor.respond-to('tablet') {
      padding: vapor.spacing('base-loose');
    }

    &:not(.bodyHidden) {
      padding-bottom: vapor.spacing('tight');
    }
  }

  .body {
    position: relative;
  }

  .bodyLoading {
    visibility: hidden
  }

  .controls {
    padding: 0 vapor.spacing('base-semi-loose');

    @include vapor.respond-to('tablet') {
      padding: 0 vapor.spacing('base-loose');
    }
  }

  .contentWrap {
    position: relative;
  }

  .content {
    padding-bottom: vapor.spacing('base-semi-loose');
    padding-left: vapor.spacing('base-semi-loose');
    padding-right: vapor.spacing('base-semi-loose');

    @include vapor.respond-to('tablet') {
      padding-bottom: vapor.spacing('base-loose');
      padding-left: vapor.spacing('base-loose');
      padding-right: vapor.spacing('base-loose');
    }
  }
  
  .spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
