@use 'vapor/typography' as *;
@import 'reset';

html, body {
  font-family: $dogpatch;
  -webkit-font-smoothing: antialiased;
}

body.prevent-scroll {
  height: 100vh;
  overflow: hidden;
}
