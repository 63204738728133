@use 'styles/vapor' as vapor;

$max-page-width: 916px;
$mobile-bottom-padding: 64px;
$tablet-bottom-padding: 120px;
$card-translateY-0: translateY(25px);
$card-translateY-100: translateY(0);
$invalid-box-shadow: inset 0 0 8px 6px vapor.color('snow', 'base-75');

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes slide-up {
  0% {
    transform: $card-translateY-0;
  }

  100% {
    transform: $card-translateY-100;
  }
}

.ActivityUpdatePage {
  margin: auto;
  max-width: $max-page-width;
  width: 100%;

  padding-top: vapor.spacing('extra-loose');
  padding-bottom: $mobile-bottom-padding;

  @include vapor.respond-to('tablet') {
    padding-top: vapor.spacing('extreme-loose');
    padding-bottom: $tablet-bottom-padding;
  }

  .content {
    position: relative;
    animation: slide-up 1s;
    margin-top: vapor.spacing('base-loose');

    @include vapor.respond-to('tablet') {
      margin-top: vapor.spacing('extra-extra-loose');
    }
  }

  .invalid {
    background: vapor.color('snow', 'base-75');
    box-shadow: $invalid-box-shadow;
  }

  .spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .contentLoading {
    visibility: hidden;
  }

  .fadeIn {
    animation: fade-in 1s;
  }
}
