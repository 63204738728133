@use 'sass:map';

$mobile-grid: 6;
$tablet-grid: 6;
$desktop-grid: 12;
$wide-grid: 12;

$grid-data: (
  'mobile': (
    display: grid,
    grid-template-columns: repeat($mobile-grid, minmax(0, 1fr)),
    column-gap: 12px,
    margin-left: 16px,
    margin-right: 16px,
  ),
  'tablet': (
    display: grid,
    grid-template-columns: repeat($tablet-grid, minmax(0, 1fr)),
    column-gap: 16px,
    margin-left: 48px,
    margin-right: 48px,
  ),
  'desktop': (
    display: grid,
    grid-template-columns: repeat($desktop-grid, minmax(0, 1fr)),
    column-gap: 24px,
    margin-left: 48px,
    margin-right: 48px,
  ),
  'wide': (
    display: grid,
    grid-template-columns: repeat($wide-grid, minmax(0, 1fr)),
    column-gap: 24px,
    margin-left: auto,
    margin-right: auto,
    max-width: 1104px,
  )
);

/// returns grid parameters for different breakpoints

@mixin grid($variant: 'mobile') {
  @if map.has-key($grid-data, $variant) {
    $fetched-variant: map.get($grid-data, $variant);

    @each $prop, $value in $fetched-variant {
      #{$prop}: $value
    }
  }
  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$variant}`. "
      + "Available variants are: #{map-keys($grid-data)}.";
  }
}

/// Returns grid item placement parameters.
///
/// @param {String} $variant - The breakpoint, used as a guide.
/// @param {number | string} $start - The starting column integer or 'full'.
/// @param {number} $end - The ending column integer. 
/// The column you want to end on plus one.
///
/// @return Grid placement for specified breakpoint.
///

$scale: (
  'mobile': $mobile-grid,
  'tablet': $tablet-grid,
  'desktop': $desktop-grid,
  'wide': $wide-grid,
);

@mixin grid-item($variant, $start: auto, $end: auto) {
  $max: map.get($scale, $variant);
  @if $start == 'full' {
    grid-column: 1 / -1;
  } @else if $start<1 or $end>$max+1 {
    @warn "The start value must be at least 1, "
      + "and the end value not greater than #{$max+1}.";
  } @else {
    grid-column-start: $start;
    grid-column-end: $end;
  }
}
