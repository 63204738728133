@use 'styles/vapor' as vapor;

.fieldset {
  border: 1px solid transparent;
  border-radius: vapor.border-radius('extra-small');
  margin: 0;
  padding: 0;
  &.error {
    border-color: vapor.color('red', 'dark-100');
  }
}

.option {
  padding-bottom: vapor.spacing('extra-tight');
  display: flex;
  align-items: center;
}

.label {
  padding-left: vapor.spacing('extra-tight');
  @include vapor.typography('body', 'small');
}

.labelContext {
  color: vapor.color('steel', 'base-50');
}

.errorText {
  color: vapor.color('red', 'dark-100');
  @include vapor.typography('caption', 'base');
  margin-top: vapor.spacing('extra-tight');
}
