@use 'styles/vapor' as vapor;

$syncing-gap: 6px;

.SyncingIndicator {
  display: inline-flex;
  vertical-align: top;
  gap: $syncing-gap;
}

.label {
  @include vapor.typography('caption', 'base');
  color: vapor.color('ink', 'base-50');
}
