@use 'sass:map';
@use 'sass:meta';

// todo update naming of breakpoints

$breakpoints: (
  'tall-mobile': ( max-height: 475px ),
  'tablet':  ( min-width:  481px ),
  'desktop': ( min-width:  769px ),
  'desktop-big': ( min-width:  1050px ),
  'wide':  ( min-width: 1440px ),
) !default;


/// Returns media query for breakpoint
///
/// @param {String} $breakpoint - window size in which a change will occur,
/// one could add to the breakpoints any scope they needed, currently set as 
/// a simple mobile-first approach
///
/// @return {Breakpoint} content scoped to chosen breakpoint

/// To use this @mixin with major breakpoints.
/// (see breakpoints above for options)
///
/// @include vapor.respond-to('desktop') {
///    <content>
/// }
///
/// If there is a component specific breakpoint aka a tweakpoint
/// first define your tweakpoint in the local .scss file
///
/// $local-tweakpoints: (
///   'inline':  ( min-width:  650px ),
///   'block': ( min-width:  980px ),
/// ) 
///
/// to implement the tweakpoint:
/// 
/// @include vapor.respond-to('block', $local-tweakpoints) {
///    <content>
/// }

@mixin respond-to($breakpoint, $store: $breakpoints) {
  @if map.has-key($store, $breakpoint) {
    @media #{meta.inspect(map.get($store, $breakpoint))} {
      @content;
    }
  }
  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
      + "Default breakpoints are: #{map.keys($breakpoints)}."
      + "If these don't meet your needs, define a local store and pass it in as a second component.";
  }
}
