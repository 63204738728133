@use 'styles/vapor' as vapor;

$tab-width: 24px;
$tab-height: 96px;
$distance-from-top: 20%;

.SlidingPanel {
  position: fixed;
  top: $distance-from-top;
  right: 0;
  z-index: vapor.z-index('tools');
  
  .container {
    display: flex;
    transition: transform 300ms ease-in-out;

    &.open {
      transform: translateX(0);
    }

    &.closed {
      transform: translateX(calc(100% - $tab-width));
    }
  }

  .content {
    background-color: vapor.color('steel', 'base-100');
    color: vapor.color('cloud', 'base-100');
    border-bottom-left-radius: vapor.border-radius('extra-small');
    border-bottom-right-radius: vapor.border-radius('extra-small');
    border-top-right-radius: vapor.border-radius('extra-small');
    box-shadow: vapor.shadow('drop-shadow-4');
    transition: width 0s ease-in-out 300ms;
    height: 0;
    width: 0;

    &.contentOpen {
      height: 100%;
      width: 100%;
    }
  }

  .tab {
    width: $tab-width;
    height: $tab-height;
    background-color: vapor.color('steel', 'base-100');
    color: vapor.color('cloud', 'base-100');
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-top-left-radius: vapor.border-radius('base');
    border-bottom-left-radius: vapor.border-radius('base');
  }

  .buttonContent {
    transform: rotate(90deg);
    @include vapor.typography('button', 'base');
    text-transform: uppercase;
    white-space: nowrap;
  }
}
