@use "styles/vapor" as vapor;

$background-size: 1200px 250px;

.LoadingPlaceholder {
  margin: 0 auto;
  width: 100%;
  height: 100%;

  &.light {
    background-color: vapor.color("steel", "base-3");
  }
  
  &.dark {
    background-color: vapor.color("cloud", "base-7");
  }
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0
  }
  100% {
    background-position: 468px 0
  }
}
 
.animatedBackground {
  animation-duration: 1.25s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  height: 100%;
  position: relative;

  &.light {
    background: vapor.color("steel", "base-50");
    background: linear-gradient(
      to right, 
      vapor.color("steel", "base-3") 10%, 
      vapor.color("milk", "base-100") 18%,
      vapor.color("steel", "base-3") 33%
    );
    background-size: $background-size;
  }

  &.dark {
    background: vapor.color("cloud", "base-50");
    background: linear-gradient(
      to right, 
      vapor.color("cloud", "base-7") 10%, 
      vapor.color("ink", "base-100") 18%,
      vapor.color("cloud", "base-7") 33%
    );
    background-size: $background-size;
  }
}
