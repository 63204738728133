@use 'styles/vapor' as vapor;

$nav-width: 240px;
$logo-height: 26px;

.SideBar {
  position: relative;
  display: none;

  @include vapor.respond-to('desktop-big') {
    display: flex;
    width: $nav-width;
    overflow: hidden;
    position: sticky;
    left: 0;
    top: 0;
    z-index: vapor.z-index('layout');
    height: 100vh;
    background-color: vapor.color('snow', 'base-100');
    border-right: 1px solid vapor.color('steel', 'base-10');

    &.hidden {
      width: 0;
    }

    transition: width 0.5s ease 0s;
  }

  @include vapor.respond-to('tall-mobile'){
    display: none;
  }
}

.innerWrap {
  display: flex;
  flex-direction: column;
  margin-top: vapor.spacing('extra-extra-loose');
  width: 100%;
}

.header {
  display: inline-flex;
  padding-left: vapor.spacing('base-loose');
  padding-bottom: vapor.spacing('base-loose');
}

.logo {
  flex: 0 0 auto;

  svg {
    @include vapor.icon-size('custom-height', $logo-height);
  }
}

.syncingIndicator {
  justify-content: center;
  align-items: center;
  margin: auto 0;
  padding-left: vapor.spacing('base-loose');
}

.navigation {
  display: flex;
  flex-direction: column;
  padding: 0 vapor.spacing('base-tight');
  gap: vapor.spacing('base');
}
