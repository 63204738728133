@use 'styles/vapor' as vapor;

$remote-config-editor-height: 416px;
$remote-config-editor-min-height: 48px;

.RemoteConfigEditor {
  display: flex;
  flex-direction: column;
  width: 100%;

  .itemsList {
    border: 1px solid vapor.color('steel', 'base-30');
    box-sizing: border-box;
    height: 100%;
    margin-top: vapor.spacing('base');
    max-height: $remote-config-editor-height;
    min-height: $remote-config-editor-min-height;
    overflow: auto;
    padding: 0 vapor.spacing('tight');
  }
}

.Filter {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  margin-top: vapor.spacing('base');
  gap: vapor.spacing('tight');

  > Input {
    padding: vapor.spacing('tight');
    flex-grow: 1;
  }
}
