@use 'sass:map';

/// Returns the sizing, positioning, or other state values for a given element.
/// Values are defined in the components local file
///
/// @param {String} $state-data - The component data.
/// @param {String} $variant [base] - The variant on the state, size, or position.
/// @return {Object} The state values for the given element.
///
@mixin get-state($state-data, $variant: 'base') {
  $fetched-variant: map.get($state-data, $variant);
  
  @each $prop, $value in $fetched-variant {
    #{$prop}: $value
  }
  // TODO: add error handling
}
