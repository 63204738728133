@use 'styles/vapor' as vapor;

.ButtonStack {
  background-color: vapor.color('white');
  border: vapor.spacing('extreme-tight') solid vapor.color('steel', 'base-100');
  min-width: max-content;
  border-radius: vapor.border-radius('extra-small');
  box-sizing: border-box;
}

.stackOption {
  width: 100%;
  display: flex;
  align-items: center;
  text-align: left;
  justify-content: left;
  padding: vapor.spacing('tight') vapor.spacing('base-tight');
  @include vapor.typography('body', 'base');
  color: vapor.color('steel', 'base-75');
  box-sizing: border-box;

  &:hover {
    background-color: vapor.color('steel', 'base-10');
    transition: background-color 0.3s ease;
  }

  &:focus-visible {
    background-color: vapor.color('steel', 'base-10');
    transition: background-color 0.3s ease;
  }

  &:active {
    background-color: vapor.color('steel', 'base-15');
  }

  &:disabled {
    background-color: transparent;
    color: vapor.color('steel', 'base-30');
    cursor: default;
    pointer-events: none;
  }
}

.optionLabel {
  width: 100%;
}