@use 'styles/vapor' as vapor;

$pod-history-label-queries: (
  baseText:  ( min-width:  248px ),
);

.podHistoryDonut {
  container: pod-history-label / inline-size;

  position: relative;
  margin-left: auto;
  margin-right: auto;
}

.chart {
  width: 100%;
  aspect-ratio: 1;
  display: block;
}

.label {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  
  pointer-events: none;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.heading {
  text-transform: uppercase;
  @include vapor.typography('overline', 'small');
  padding: vapor.spacing('none');

  @include vapor.respond-to-container('baseText', $pod-history-label-queries) {
    @include vapor.typography('overline', 'base');
    padding-left: vapor.spacing('extra-tight');
    padding-right: vapor.spacing('extra-tight');
  }
}

.divider {
  width: 100%;
  height: vapor.spacing('tightest');
  background-color: vapor.color('steel', 'base-15');
  margin-top: vapor.spacing('extra-tight');
  margin-bottom: vapor.spacing('extra-tight');

  @include vapor.respond-to-container('baseText', $pod-history-label-queries) {
    margin-top: vapor.spacing('tighter');
    margin-bottom: vapor.spacing('tighter');
  }
}

.podFlavor {
  text-transform: uppercase;
  @include vapor.typography('overline', 'small');

  @include vapor.respond-to-container('baseText', $pod-history-label-queries) {
    @include vapor.typography('subtitle', 'small');
  }
}

.podStrength {
  @include vapor.typography('caption', 'small');

  @include vapor.respond-to-container('baseText', $pod-history-label-queries) {
    @include vapor.typography('caption', 'base');
  }
}

.podCount {
  @include vapor.typography('headline-h4', 'mobile');

  @include vapor.respond-to-container('baseText', $pod-history-label-queries) {
    @include vapor.typography('headline-h3', 'mobile');
  }
}
