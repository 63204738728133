@use 'styles/vapor' as vapor;

$mobile-logo-width: 160px;
$tablet-logo-width: 200px;
$left-card-max-width: 450px;
$max-button-width: 320px;

.JuulMissionPage {
  height: 100vh;
  display: flex;
  position: relative;
  flex-direction: column;

  @include vapor.respond-to('desktop') {
    flex-direction: row;
  }
  
  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 50%;
    padding: vapor.spacing('base-loose') max(vapor.spacing('base-loose'), 10%);

    @include vapor.respond-to('desktop') {
      padding: vapor.spacing('extra-loose');
      width: 50%;
      height: auto;
    }
  }

  .leftColumn {
    background-color: vapor.color('black', 'base-100');
  }

  .rightColumn {
    background-color: vapor.color('white', 'base-100');
  }

  .leftCard {
    margin: auto;
    color: vapor.color('milk', 'base-100');
    text-align: center;
    max-width: $left-card-max-width;
  }

  .rightCard {
    margin: auto;
    color: vapor.color('ink', 'base-100');
    text-align: center;

    @include vapor.respond-to('desktop') {
      margin-left: 0;
      text-align: left;
    }
  }

  .logo {
    display: inline-flex;
    margin-bottom: vapor.spacing('base-loose');

    svg {
      @include vapor.icon-size('custom-width', $mobile-logo-width);
    }
    
    @include vapor.respond-to('tablet') {
      margin-bottom: vapor.spacing('loose');
    
      svg {
        @include vapor.icon-size('custom-width', $tablet-logo-width);
      }
    }
  }

  .mission {
    @include vapor.typography('body', 'base');

    @include vapor.respond-to('tablet') {
      @include vapor.typography('body', 'big');
    }
  }
}
