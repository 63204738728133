@use 'styles/vapor' as vapor;

$puff-history-queries: (
  halfWidthDate:  ( min-width:  338px ),
);

.puffHistoryControls {
  container: puff-history-controls / inline-size;
  
  .datePickers {
    display: flex;
    padding-top: vapor.spacing('base');
  }

  .date {
    width: 100%;

    @include vapor.respond-to-container('halfWidthDate', $puff-history-queries) {
      width: 50%;
    }
  }
}
