@use 'styles/vapor' as vapor;

div.Controls {
  display: flex;
  height: initial;
  
  &:empty {
    display: none;
  }

  &.stacked {
    flex-direction: column;
  
    &.alignLeft {
      align-items: start;
    }
  
    &.alignRight {
      align-items: end;
    }
  
    &.alignCenter {
      align-items: center;
    }
  }

  &.inline {
    flex-direction: row;
  
    &.alignLeft {
      justify-content: start;
    }
  
    &.alignRight {
      justify-content: end;
    }
  
    &.alignCenter {
      justify-content: center;
    }
  }

  &.gapNone {
    gap: vapor.spacing('none');
  }
  
  &.gapExtremeTight {
    gap: vapor.spacing('extreme-tight');
  }
  
  &.gapExtraTight {
    gap: vapor.spacing('extra-tight');
  }
  
  &.gapTight {
    gap: vapor.spacing('tight');
  }
  
  &.gapBaseTight {
    gap: vapor.spacing('base-tight');
  }
  
  &.gapBase {
    gap: vapor.spacing('base')
  }
  
  &.gapBaseLoose {
    gap: vapor.spacing('base-loose')
  }
  
  &.gapLoose {
    gap: vapor.spacing('loose');
  }
}
