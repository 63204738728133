@use 'styles/vapor' as vapor;

$spinner-sizing: (
  'base': (
    border-width: 4.5px,
    width: 24px,
    height: 24px,
  ),
  'small': (
    border-width: 3px,
    height: 16px,
    width: 16px,
  )
);

.Spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.circle {
  border-radius: 50%;
  animation: spin 1s linear infinite;
  box-sizing: border-box;
  border: 0 solid transparent;
}

.colorLight {
  border-color: vapor.color('cloud', 'base-15');
  border-top-color: vapor.color('milk');
}

.colorDark {
  border-color: vapor.color('steel', 'base-10');
  border-top-color: vapor.color('ink');
}

.sizeBase {
  @include vapor.get-state($spinner-sizing, 'base')
}

.sizeSmall {
  @include vapor.get-state($spinner-sizing, 'small')
}

.visuallyHidden {
  @include vapor.visually-hidden()
}

@keyframes spin {
  to { transform: rotate(360deg); }
}
