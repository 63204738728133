@use 'styles/vapor' as vapor;

.NicotineWarningDefault {
  border: 1px solid vapor.color('white', 'base-100');
  background-color: vapor.color('black', 'base-100');
  padding: vapor.spacing('tighter');
  position: relative;
  color: vapor.color('black', 'base-100');
  text-align: center;
  @include vapor.typography('headline-h4', 'mobile');

  @include vapor.respond-to('desktop') {
    @include vapor.typography('headline-h3', 'base');
  }
}

.content {
  background-color: vapor.color('white', 'base-100');
  padding: vapor.spacing('base') vapor.spacing('base-tight');

  @include vapor.respond-to('tablet') {
    white-space: pre-wrap;
  }
}
