@use 'styles/vapor' as vapor;

$log-list-height: 416px;
$log-list-min-height: 48px;

.ConsoleLogsDisplay {
  display: flex;
  flex-direction: column;
  width: 100%;

  .logList {
    box-sizing: border-box;
    border: 1px solid vapor.color('steel', 'base-30');
    padding: 0 vapor.spacing('tight');
    max-height: $log-list-height;
    height: $log-list-height;
    min-height: $log-list-min-height;
    overflow: auto;

    & * {
      overflow-anchor: none;
    }
  }
  
  .logListItem {
    border-bottom: 1px solid vapor.color('steel', 'base-30');
    padding: vapor.spacing('tight') 0;

    &:last-of-type {
      border-bottom: none;
    }
  }
  
  .logText {
    overflow-wrap: anywhere;
  }

  .anchor {
    overflow-anchor: auto;
    height: 1px;
  }

  .actionWrap {
    display: flex;
    align-items: center;
  }
}
