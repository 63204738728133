@use 'sass:map';

$dogpatch: "Dogpatch Sans";

@font-face {
  font-family: $dogpatch;
  font-weight: 300 700; // provides a range from 300-700 for our variable font
  font-style: normal;
  font-display: swap;
  src:
    url("/fonts/dogpatch.woff2") format("woff2"),
    url("/fonts/dogpatch.woff") format("woff");
}

$typography-data: (
  'headline-h1': (
    'base': (
      font-family: $dogpatch,
      font-size: 64px,
      font-style: normal,
      font-weight: 700,
      line-height: 80px,
      letter-spacing: 0.3px
    ),
    'mobile': (
      font-family: $dogpatch,
      font-size: 48px,
      font-style: normal,
      font-weight: 700,
      line-height: 60px,
      letter-spacing: 0.3px
    )
  ),

  'headline-h2': (
    'base': (
      font-family: $dogpatch,
      font-size: 40px,
      font-style: normal,
      font-weight: 700,
      line-height: 50px,
      letter-spacing: 0.3px
    ),
    'mobile': (
      font-family: $dogpatch,
      font-size: 32px,
      font-style: normal,
      font-weight: 700,
      line-height: 40px,
      letter-spacing: 0.3px
    )
  ),

  'headline-h3': (
    'base': (
      font-family: $dogpatch,
      font-size: 30px,
      font-style: normal,
      font-weight: 700,
      line-height: 36px,
      letter-spacing: 0.3px
    ),
    'mobile': (
      font-family: $dogpatch,
      font-size: 25px,
      font-style: normal,
      font-weight: 700,
      line-height: 30px,
      letter-spacing: 0.3px
    )
  ),

  'headline-h4': (
    'base': (
      font-family: $dogpatch,
      font-size: 22px,
      font-style: normal,
      font-weight: 700,
      line-height: 26px,
      letter-spacing: 0.3px
    ),
    'mobile': (
      font-family: $dogpatch,
      font-size: 20px,
      font-style: normal,
      font-weight: 700,
      line-height: 24px,
      letter-spacing: 0.3px
    )
  ),

  'subtitle': (
    'base': (
      font-family: $dogpatch,
      font-size: 15px,
      font-style: normal,
      font-weight: 600,
      line-height: 18px,
      letter-spacing: 1px,
      text-transform: uppercase
    ),
    'small': (
      font-family: $dogpatch,
      font-size: 12px,
      font-style: normal,
      font-weight: 600,
      line-height: 16px,
      letter-spacing: 0.8px,
      text-transform: uppercase
    )
  ),

  'body': (
    'base': (
      font-family: $dogpatch,
      font-size: 17px,
      font-style: normal,
      font-weight: 400,
      line-height: 24px,
      letter-spacing: 0px
    ),
    'base-bold' : (
      font-family: $dogpatch,
      font-size: 17px,
      font-style: normal,
      font-weight: 600,
      line-height: 24px,
      letter-spacing: 0px
    ),
    'big': (
      font-family: $dogpatch,
      font-size: 21px,
      font-style: normal,
      font-weight: 400,
      line-height: 28px,
      letter-spacing: 0px
    ),
    'big-bold': (
      font-family: $dogpatch,
      font-size: 21px,
      font-style: normal,
      font-weight: 600,
      line-height: 28px,
      letter-spacing: 0px
    ),
    'small': (
      font-family: $dogpatch,
      font-size: 15px,
      font-style: normal,
      font-weight: 400,
      line-height: 20px,
      letter-spacing: 0px
    ),
    'small-bold': (
      font-family: $dogpatch,
      font-size: 15px,
      font-style: normal,
      font-weight: 600,
      line-height: 20px,
      letter-spacing: 0px
    ),
  ),

  'caption': (
    'base': (
      font-family: $dogpatch,
      font-size: 13px,
      font-style: normal,
      font-weight: 400,
      line-height: 18px,
      letter-spacing: 0.25px
    ),
    'base-bold': (
      font-family: $dogpatch,
      font-size: 13px,
      font-style: normal,
      font-weight: 600,
      line-height: 18px,
      letter-spacing: 0.25px
    ),
    'small': (
      font-family: $dogpatch,
      font-size: 11px,
      font-style: normal,
      font-weight: 400,
      line-height: 13px,
      letter-spacing: 0.25px
    ),
    'small-bold': (
      font-family: $dogpatch,
      font-size: 11px,
      font-style: normal,
      font-weight: 600,
      line-height: 13px,
      letter-spacing: 0.25px
    )
  ),

  'button': (
    'base': (
      font-family: $dogpatch,
      font-size: 11px,
      font-style: normal,
      font-weight: 600,
      line-height: 16px,
      letter-spacing: 1px
    ),
    'large': (
      font-family: $dogpatch,
      font-size: 14px,
      font-style: normal,
      font-weight: 600,
      line-height: 24px,
      letter-spacing: 0.25px
    )
  ),

  'overline': (
    'base': (
      font-family: $dogpatch,
      font-size: 11px,
      font-style: normal,
      font-weight: 500,
      line-height: 16px,
      letter-spacing: 1px
    ),
    'small': (
      font-family: $dogpatch,
      font-size: 8px,
      font-style: normal,
      font-weight: 500,
      line-height: 8px,
      letter-spacing: 0.8px
    )
  ),

  'tag': (
    'base': (
      font-family: $dogpatch,
      font-size: 9px,
      font-style: normal,
      font-weight: 600,
      line-height: 12px,
      letter-spacing: 0.5px
    )
  ),
);

/// Returns the typography values for the font style.
///
/// @param {String} $fontStyle - The font style.
/// @param {String} $variant [base] - The variant on the font-size.
/// @return {Object} The typography values for the font style.
///
@mixin typography($fontStyle, $variant: 'base') {
  $fetched-variant: map.get(map.get($typography-data, $fontStyle), $variant);

  @each $prop, $value in $fetched-variant {
    #{$prop}: $value
  }

  // TODO: add error handling
}

