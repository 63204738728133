@use 'styles/vapor' as vapor;

aside.CloseableNotice {
  position: relative;
  max-width: 100%;
  background-color: vapor.color('white');
  border-radius: vapor.border-radius('extreme-large');
  border: 1px solid vapor.color('steel', 'base-15');
  color: vapor.color('ink', 'base-100');
  display: flex;

  padding: {
    top: vapor.spacing('base');
    left: vapor.spacing('base');
    bottom: vapor.spacing('base');
    right: vapor.spacing('loose');
  };
  
  @include vapor.respond-to('desktop') {
    padding-top: vapor.spacing('base-loose');
    padding-bottom: vapor.spacing('base-loose');
    padding-left: vapor.spacing('base-loose');
  };

  .body {
    margin: auto 0;
  }

  .closeButton {
    position: absolute;
    right: vapor.spacing('tight');
    top: vapor.spacing('tight');
  }
}
