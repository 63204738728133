@use 'sass:map';
@use 'utilities';

$icon-sizing: (
  'custom-height': (
    width: auto,
  ),
  'custom-width': (
    height: auto,
  ),
  'jumbo': (
    height: 48px,
    width: auto,
  ),
  'large': (
    height: 32px,
    width: auto,
  ),
  'medium': (
    height: 24px,
    width: auto,
  ),
  'small': (
    height: 16px,
    width: auto,
  ),
);

/// Returns the icon size values for the icon size provided.
///
/// @param {String} $size - The icon size.
/// @param {String} $custom-size - Optional custom size.
/// @return {Object} The icon size styles.
///
@mixin icon-size($size: 'medium', $custom-size: null) {
  $fetched-size: map.get($icon-sizing, $size);

  @if $size == 'custom-height' and $custom-size != null {
    $fetched-size: map-merge($fetched-size, (height: $custom-size));
  }

  @if $size == 'custom-width' and $custom-size != null {
    $fetched-size: map-merge($fetched-size, (width: $custom-size));
  }

  @each $prop, $value in $fetched-size {
    #{$prop}: $value;
  }
}
