@use 'styles/vapor' as vapor;

$description-max-width: 280px;
$icon-height: 36px;

.ThankYou {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: vapor.spacing('base-loose');
  height: 100%;
}

.description {
  max-width: $description-max-width;
}

.icon {
  height: $icon-height;

  svg {
    @include vapor.icon-size('custom-height', $icon-height);
  }
}