@use 'styles/vapor' as vapor;

$local-tweakpoints: (
  'mid-mobile':  ( min-width:  348px ),
);

$header-height: 56px;
$max-header-width: 1400px;

$mobile-margin: 16px;
$tablet-margin: 48px;
$logo-height: 26px;

.Header {
  width: 100%;
  height: $header-height;
  background-color: vapor.color('white');
  box-sizing: border-box;
  border-top: 1px solid vapor.color('steel', 'base-10');
  border-bottom: 1px solid vapor.color('steel', 'base-10');

  &.hidden {
    display: none;
  }

  &.visible {
    display: block;

    &.responsive {
      @include vapor.respond-to('desktop-big') {
        display: none;
      }
      @include vapor.respond-to('tall-mobile') {
        display: block;
      }
    }
  } 
}

.wideWrap {
  max-width: $max-header-width;
  height: 100%;
  width: 100%;
  margin: 0 auto;
}

.content {
  position: relative;
  height: 100%;
  margin: 0 $mobile-margin;
  
  @include vapor.respond-to('tablet') {
    margin: 0 $tablet-margin;
  }
}

.logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  svg {
    @include vapor.icon-size('custom-height', $logo-height);
  }
}

.syncingIndicator {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
}

.syncingIndicatorLabel {
  display: none;

  @include vapor.respond-to('mid-mobile', $local-tweakpoints) {
    display: block;
  }
}
