@use 'styles/vapor' as vapor;

$nav-button-sizing: (
  'desktop': (
    height: 48px,
    padding: 12px 12px,
    border-radius: 10px,
  ),
  'mobileTablet': (
    height: 100%,
    padding: 6px 4px 4px 4px,
  ),
);

$indicator-size: 7px;
$indicator-positioning: (
  'desktop': (
    display: inline-flex,
    margin-left: 4px,
    margin-top: 3.5px,
  ),
  'mobileTablet': (
    position: absolute,
    left: calc(50% + 13px),
    top: 5px,
  ),
);

.NavButton {
  position: relative;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  box-sizing: border-box;

  &.activeNav {
    color: vapor.color('ink', 'base-100');
  }

  &.disabled {
    color: vapor.color('steel', 'base-15');
    cursor: default;
    pointer-events: none;
  }

  &:focus-visible {
    background-color: vapor.color('steel', 'base-10');
    transition: background-color 0.3s ease;
  }
}

.desktop {
  color: vapor.color('ink', 'base-75');
  @include vapor.get-state($nav-button-sizing, 'desktop');

  &.activeNav:not(:focus-visible, :hover) {
    background-color: vapor.color('steel', 'base-5');
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background-color: vapor.color('steel', 'base-10');
      transition: background-color 0.3s ease;
    }
  }
}

.mobileTablet {
  color: vapor.color('ink', 'base-50');
  @include vapor.get-state($nav-button-sizing, 'mobileTablet');
  border-top: vapor.spacing('extreme-tight') solid transparent;
  flex-direction: column;

  &.activeNav {
    border-color: vapor.color('steel', 'base-100');

    &.disabled {
      border-color: vapor.color('steel', 'base-15');
    }
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      border-color: vapor.color('steel', 'base-100');
      transition: border-color 0.3s ease;
    }
  }
}

.content {
  display: inline-flex;
  min-width: 1px;
  min-height: 1px;
  width: 100%;
  white-space: pre;
  flex-shrink: 0;

  .desktop & {
    justify-content: flex-start;
    text-transform: capitalize;
    @include vapor.typography('body', 'small');
    padding-left: vapor.spacing('tight');
    width: auto;
  }

  .mobileTablet & {
    justify-content: center;
    text-transform: uppercase;
    @include vapor.typography('tag');
    @include vapor.respond-to('desktop') {
      font-size: 10px;
    }
  }
}

.indicator {
  flex-shrink: 0;
  width: $indicator-size;
  height: $indicator-size;
  background-color: vapor.color('blue', 'lighter-100');
  border-radius: vapor.border-radius('round');

  &.disabled {
    background-color: vapor.color('steel', 'base-15');
  }
  
  .desktop & {
    @include vapor.get-state($indicator-positioning, 'desktop');
  }

  .mobileTablet & {
    @include vapor.get-state($indicator-positioning, 'mobileTablet');
  }
}

.icon {
  flex-shrink: 0;

  svg {
    @include vapor.icon-size('medium');
  }
}
