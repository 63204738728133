@use 'styles/vapor' as vapor;

.Link {
  display: inline;
  text-decoration: underline;
  color: vapor.color('blue', 'base-100');

  &.disabled {
    color: vapor.color('steel', 'base-30');
    cursor: default;
    pointer-events: none;
  }

  &:focus-visible {
    outline: 1.5px solid vapor.color('blue', 'base-100');
    outline-offset: vapor.spacing('extreme-tight');
    border-radius: vapor.border-radius('extreme-small');
  }
}
