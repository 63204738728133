@use 'styles/vapor' as vapor;

.FlexColumn {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;

  &:empty {
    display: none;
  }
}

@each $spacing, $spacingValue in vapor.$spacing-data {
  .default-spacing-#{$spacing} {
    gap: vapor.spacing($spacing);
  }

  @each $breakpoint, $breakpointValue in vapor.$breakpoints {
    .#{$breakpoint}-spacing-#{$spacing} {
      @include vapor.respond-to($breakpoint) {
        gap: vapor.spacing($spacing);
      }
    }
  }
}
