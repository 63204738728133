@use 'styles/vapor' as vapor;

.NicotineWarningCA {
  position: relative;

  svg {
    width: 100%;
    height: 100%;
  }

  .mobileEnglish {
    display: block;
  
    @include vapor.respond-to('tablet') {
      display: none;
    }
  }

  .tabletEnglish {
    display: none;
  
    @include vapor.respond-to('tablet') {
      display: block;
    }

    @include vapor.respond-to('desktop') {
      display: none;
    }
  }

  .desktopEnglish {
    display: none;
  
    @include vapor.respond-to('desktop') {
      display: block;
    }

    @include vapor.respond-to('wide') {
      display: none;
    }
  }

  .wideEnglish {
    display: none;

    @include vapor.respond-to('wide') {
      display: block;
    }
  }

  .mobileFrench {
    display: block;
  
    @include vapor.respond-to('desktop') {
      display: none;
    }
  }

  .desktopFrench {
    display: none;

    @include vapor.respond-to('desktop') {
      display: block;
    }
  }
}
