@use 'styles/vapor' as vapor;

header.InsightsHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .toggleInsightButton {
    @include vapor.typography('button', 'base');
    text-transform: uppercase;
    color: vapor.color('graphica', 'base-100');
    display: flex;
    gap: vapor.spacing('tighter');
    padding-left: vapor.spacing('extreme-tight');

    &:focus-visible {
      outline: 1.5px solid vapor.color('graphica', 'light-100');
      outline-offset: vapor.spacing('extra-tight');
      border-radius: vapor.border-radius('extreme-small');
    }
  }
}
