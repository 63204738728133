@use 'styles/vapor' as vapor;

.GridItem {
  grid-column: 1 / -1;

  &.verticalAlignCenter {
    margin: auto 0;
  }

  &.verticalAlignTop { 
    margin: 0 0 auto 0;
  }
}
