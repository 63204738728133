@use 'styles/vapor' as vapor;

$card-translateY-0: translateY(25px);
$card-translateY-100: translateY(0);

@keyframes fade-in {
  0% {
    opacity: 0;
    transform: $card-translateY-0;
  }

  100% {
    opacity: 1;
    transform: $card-translateY-100;
  }
}

.InsightsSection > .animateContent {
  animation: fade-in 1s;
}

.body {
  position: relative;
}
