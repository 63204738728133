@use 'styles/vapor' as vapor;

.NicotineWarningUS {
  background-color: vapor.color('white', 'base-100');
  padding: vapor.spacing('base-tight');
  position: relative;
  color: vapor.color('white', 'base-100');
  text-align: center;
  
  @include vapor.typography('headline-h4', 'mobile');

  @include vapor.respond-to('desktop') {
    @include vapor.typography('headline-h3', 'base');
  }

  @include vapor.respond-to('tall-mobile') {
    background-color: vapor.color('white', 'base-100');
  }
}

.content {
  background-color: vapor.color('black', 'base-100');
  padding: vapor.spacing('base') vapor.spacing('base-tight');
  @include vapor.respond-to('desktop') {
    white-space: pre;
  }
}

.lineBreak {
  display: none;

  @include vapor.respond-to('desktop') {
    display: block;
  }
}
