@use 'styles/vapor' as vapor;

$textarea-min-height: 116px;

.Textarea {
  position: relative;
  width: 100%;
  height: 100%;
  border: 1px solid vapor.color('steel', 'base-15');
  border-radius: vapor.border-radius('extra-small');
  box-sizing: border-box;
  background-color: vapor.color('white', 'base-100');
  text-align: left;

  @media (hover: hover) and (pointer: fine) {
    &:not(.readOnly, .disabled):hover {
      border-color: vapor.color('steel', 'base-50');
    }
  }

  &.labelHidden:not(.readOnly, .disabled):focus-within, 
  &.labelHidden:not(.readOnly, .disabled):active {
    border-color: vapor.color('steel', 'base-100');
  }

  &.disabled {
    border-color: transparent;
    background-color: vapor.color('steel', 'base-3');
  }

  &.error {
    border-color: vapor.color('red', 'dark-100');
  }
}

.textareaInput {
  width: 100%;
  height: 100%;
  min-height: $textarea-min-height;
  outline: 0px;
  border: 0px;
  border-radius: vapor.border-radius('extra-small');
  background-color: vapor.color('white', 'base-100');
  box-sizing: border-box;
  color: vapor.color('ink', 'base-100');
  @include vapor.typography('body', 'small');
  resize: none;
  vertical-align: bottom;
  padding: vapor.spacing('base-tight');
  
  &:disabled {
    color: vapor.color('steel', 'base-30');
    background-color: transparent;
  }
}

.textareaLabel {
  pointer-events: none;
  width: 100%;
  height: 100%;
  position: absolute;
  padding: vapor.spacing('base-tight');
  box-sizing: border-box;
  overflow: hidden;
  color: vapor.color('steel', 'base-50');
  @include vapor.typography('body', 'small');

  &.disabled {
    color: vapor.color('steel', 'base-30');
  }

  &.labelHidden {
    display: none;
  }
}

.errorText {
  color: vapor.color('red', 'dark-100');
  @include vapor.typography('caption', 'base');
  margin-top: vapor.spacing('extra-tight');
  text-align: left;
}

.helperText {
  text-transform: uppercase;
  color: vapor.color('steel', 'base-75');
  @include vapor.typography('overline', 'small');
  margin-top: vapor.spacing('extra-tight');
  text-align: right;
}
