@use 'styles/vapor' as vapor;

$player-sizing: (
  'default': (
    height: 178px,
  ),
  'mobile': (
    height: 127px,
  )
);

.player {
  @include vapor.get-state($player-sizing, 'mobile');

  @include vapor.respond-to('tablet') {
    @include vapor.get-state($player-sizing, 'default');
  }

  rect {
    fill: transparent;
  }
}
