@use 'styles/vapor' as vapor;

.Text {
  @each $color, $colorMap in vapor.$color-data {
    @each $variant, $variantMap in $colorMap {
      &.#{$color}-#{$variant} {
        color: vapor.color($color, $variant);
      }
    }
  }

  &.alignLeft {
    text-align: left;
  }
  
  &.alignCenter {
    text-align: center;
  }
  
  &.alignRight {
    text-align: right;
  }
  
  &.alignJustify {
    text-align: justify;
  }
  
  &.alignInherit {
    text-align: inherit;
  }
  
  &.alignInitial {
    text-align: initial;
  }

  &.typographyH1 {
    @include vapor.typography('headline-h1', 'mobile');

    @include vapor.respond-to('tablet') {
      @include vapor.typography('headline-h1', 'base');
    }
  }

  &.typographyH1Base {
    @include vapor.typography('headline-h1', 'base');
  }

  &.typographyH1Mobile {
    @include vapor.typography('headline-h1', 'mobile');
  }

  &.typographyH2 {
    @include vapor.typography('headline-h2', 'mobile');

    @include vapor.respond-to('tablet') {
      @include vapor.typography('headline-h2', 'base');
    }
  }

  &.typographyH2Base {
    @include vapor.typography('headline-h2', 'base');
  }

  &.typographyH2Mobile {
    @include vapor.typography('headline-h2', 'mobile');
  }

  &.typographyH3 {
    @include vapor.typography('headline-h3', 'mobile');

    @include vapor.respond-to('tablet') {
      @include vapor.typography('headline-h3', 'base');
    }
  }

  &.typographyH3Base {
    @include vapor.typography('headline-h3', 'base');
  }

  &.typographyH3Mobile {
    @include vapor.typography('headline-h3', 'mobile');
  }

  &.typographyH4 {
    @include vapor.typography('headline-h4', 'mobile');

    @include vapor.respond-to('tablet') {
      @include vapor.typography('headline-h4', 'base');
    }
  }

  &.typographyH4Base {
    @include vapor.typography('headline-h4', 'base');
  }

  &.typographyH4Mobile {
    @include vapor.typography('headline-h4', 'mobile');
  }

  &.typographySubtitleBase {
    @include vapor.typography('subtitle', 'base');
  }

  &.typographySubtitleSmall {
    @include vapor.typography('subtitle', 'small');
  }

  &.typographyBodyBase {
    @include vapor.typography('body', 'base');

    b, strong {
      @include vapor.typography('body', 'base-bold');
    }
  }

  &.typographyBodyBaseBold {
    @include vapor.typography('body', 'base-bold');
  }

  &.typographyBodyBig {
    @include vapor.typography('body', 'big');

    b, strong {
      @include vapor.typography('body', 'big-bold');
    }
  }

  &.typographyBodyBigBold {
    @include vapor.typography('body', 'big-bold');
  }

  &.typographyBodySmall {
    @include vapor.typography('body', 'small');

    b, strong {
      @include vapor.typography('body', 'small-bold');
    }
  }

  &.typographyBodySmallBold {
    @include vapor.typography('body', 'small-bold');
  }

  &.typographyCaptionBase {
    @include vapor.typography('caption', 'base');

    b, strong {
      @include vapor.typography('caption', 'base-bold');
    }
  }

  &.typographyCaptionBaseBold {
    @include vapor.typography('caption', 'base-bold');
  }

  &.typographyCaptionSmall {
    @include vapor.typography('caption', 'small');

    b, strong {
      @include vapor.typography('caption', 'small-bold');
    }
  }

  &.typographyCaptionSmallBold {
    @include vapor.typography('caption', 'small-bold');
  }

  &.typographyOverlineBase {
    @include vapor.typography('overline', 'base');
    text-transform: uppercase;
  }

  &.typographyOverlineSmall {
    @include vapor.typography('overline', 'small');
    text-transform: uppercase;
  }

  &.typographyTag {
    @include vapor.typography('tag', 'base');
    text-transform: uppercase;
  }
}
