@use 'styles/vapor' as vapor;

$textarea-min-height: 118px;
$input-height: 46px;
$icon-padding: 48px;

.InputField {
  position: relative;
  width: 100%;
  border: 1px solid vapor.color('steel', 'base-15');
  border-radius: vapor.border-radius('extra-small');
  box-sizing: border-box;
  background-color: vapor.color('white', 'base-100');

  @media (hover: hover) and (pointer: fine) {
    &:not(.readOnly, .disabled):hover {
      border-color: vapor.color('steel', 'base-50');
    }
  }

  &.floating:not(.readOnly, .disabled):focus-within, 
  &.floating:not(.readOnly, .disabled):active {
    border-color: vapor.color('steel', 'base-100');
  }

  &.disabled {
    border-color: transparent;
    background-color: vapor.color('steel', 'base-3');
  }

  &.error {
    border-color: vapor.color('red', 'dark-100');
  }

  // https://css-tricks.com/snippets/css/change-autocomplete-styles-webkit-browsers/
  // we don't require media hover here bc this is simply coloring the text
  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus {
    -webkit-text-fill-color: vapor.color('steel', 'base-100');
  }
}

.inputCommon {
  width: 100%;
  outline: 0px;
  border: 0px;
  border-radius: vapor.border-radius('extra-small');
  background-color: vapor.color('white', 'base-100');
  box-sizing: border-box;
  color: vapor.color('steel', 'base-100');
  @include vapor.typography('body', 'base');
  
  &:disabled {
    color: vapor.color('steel', 'base-30');
    background-color: transparent;
  }

  &::placeholder {
    transition: color 0.2s ease-in-out;
    color: transparent;
  }

  &.floating::placeholder {
    color: vapor.color('steel', 'base-50');
  }

  &::-ms-reveal,
  &::-ms-clear {
    display: none;
  }

  &.textarea {
    resize: none;
    vertical-align: bottom;
    min-height: $textarea-min-height;
    margin-top: vapor.spacing('base-loose');
    padding:
      vapor.spacing('none')
      vapor.spacing('base-tight')
      vapor.spacing('base-tight')
      vapor.spacing('base-tight');
  }

  &.input {
    height: $input-height;
    padding: 
      vapor.spacing('base')
      vapor.spacing('base-tight')
      vapor.spacing('tight')
      vapor.spacing('base-tight');
  }

  &.secure {
    padding-right: $icon-padding;
  }
}

.labelCommon {
  text-transform: uppercase;
  pointer-events: none;
  position: absolute;
  transform: translate(0, -50%);
  top: 50%;
  left: vapor.spacing('base-tight');
  right: vapor.spacing('base-tight');
  color: vapor.color('steel', 'base-75');
  @include vapor.typography('overline', 'base');
  transition: all 0.2s ease-in-out;

  &.disabled {
    color: vapor.color('steel', 'base-30');
  }

  &.labelTextarea {
    transform: translate(0, 0);
    top: vapor.spacing('base-tight');
  }

  &.floating {
    top: vapor.spacing('base-tight');
    @include vapor.typography('overline', 'small');
  }
}

.InputField .showHideButton {
  position: absolute;
  transform: translate(0, -50%);
  top: 50%;
  right: vapor.spacing('base-tight');
}

.errorText {
  color: vapor.color('red', 'dark-100');
  @include vapor.typography('caption', 'base');
  margin-top: vapor.spacing('extra-tight');
}

.helperText {
  text-transform: uppercase;
  color: vapor.color('steel', 'base-75');
  @include vapor.typography('overline', 'small');
  margin-top: vapor.spacing('extra-tight');
  text-align: right;
}
