@use 'styles/vapor' as vapor;

$image-z-index: 1;

.JuulLoading {
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  &.light {
    background-color: vapor.color('white');
  }

  &.dark {
    background-color: vapor.color('black');
  }

  .loader {
    width: 90vw;
    max-width: calc(769px * 0.9);
    aspect-ratio: 280/129;
    position: relative;
  }

  .hide {
    display: none;
  }

  .image, .animation {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .image {
    z-index: $image-z-index;
  }
}
