@use 'styles/vapor' as vapor;

$activity-updates-queries: (
  twoColumns:  ( min-width:  610px ),
);
$span-full-screen: 2;
$span-half-screen: 1;

.ActivityUpdates {
  container: activityUpdates / inline-size;

  .activityUpdateLinks {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: 1fr;
    gap: vapor.spacing('base-tight');
  
    > .previewCard {
      grid-column: span $span-full-screen;
    }
  
    @include vapor.respond-to-container('twoColumns', $activity-updates-queries) {
      &.twoColumns > .previewCard {
        grid-column: span $span-half-screen;
      }
    }
  }
}
