@use 'styles/vapor' as vapor;

$footer-height: 64px;
$side-bar-width: 240px;

.Layout {
  background-color: vapor.color('snow', 'base-100');

  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  @include vapor.respond-to('desktop-big') {
    flex-direction: row;

    &.divider {
      border-top: 1px solid vapor.color('steel', 'base-10');
    }
  }
  
  @include vapor.respond-to('tall-mobile') {
    flex-direction: column;
  }
}

.content {
  display: flex;
  flex-flow: column;
  flex: 1;
  
  &.animate {
    margin-bottom: $footer-height;

    &.navigationHidden {
      margin-bottom: 0px;
    }

    @include vapor.respond-to('desktop-big') {
      margin-bottom: 0px;
    }

    @include vapor.respond-to('tall-mobile') {
      margin-bottom: $footer-height;
    }
  }
}

.stickyBanners {
  width: 100%;
  height: auto;
  position: sticky;
  top: 0;
  overflow: hidden;
  z-index: vapor.z-index('layout');
}

.notificationBanner {
  background-color: vapor.color('red', 'base-100');

  >.notificationBannerLink {
    color: vapor.color('white', 'base-100');
  }
}
