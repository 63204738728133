@use 'styles/vapor' as vapor;

.RemoteConfigItem {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-bottom: 1px solid vapor.color('steel', 'base-30');
  padding: vapor.spacing('tight') 0;

  &:last-of-type {
    border-bottom: none;
  }
}

.ValueDisplay {
  display: flex;

  &SingleLine {
    @extend .ValueDisplay;
    flex-direction: row;
    align-items: baseline;
    gap: vapor.spacing('base');
  }

  &MultiLine {
    @extend .ValueDisplay;
    flex-direction: column;
    white-space: pre-wrap;
  }
}

.Editor {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: vapor.spacing('base');

  &TextArea {
    width: 100%;
  }
}
