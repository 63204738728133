@use 'styles/vapor' as vapor;

.UnstyledLink {
  @include vapor.unstyled-link();

  &.disabled {
    cursor: default;
    pointer-events: none;
  }
}
