@use 'styles/vapor' as vapor;

.gridWrap {
  display: flex;
  flex: 1 1;
}

.Grid {
  width: 100%;
  @include vapor.grid('mobile');
  @include vapor.respond-to('tablet') {
    @include vapor.grid('tablet');
  }
  @include vapor.respond-to('desktop') {
    @include vapor.grid('desktop');
  }
  @include vapor.respond-to('wide') {
    @include vapor.grid('wide');
  }
}

.fullWidth {
  max-width: unset;
  margin-left: 0;
  margin-right: 0;
}
