@use 'styles/vapor' as vapor;

// display

.typeBlock {
  display: block;
}

.typeInline {
  display: inline-block;
}

// align
.alignLeft {
  text-align: left;
}

.alignCenter {
  text-align: center;
}

.alignRight {
  text-align: right;
}

.alignJustify {
  text-align: justify;
}

.alignInherit {
  text-align: inherit;
}

.alignInitial {
  text-align: initial;
}

// margin

.marginNone {
  margin: vapor.spacing('none');
}

.marginExtremeTight {
  margin: vapor.spacing('extreme-tight');
}

.marginExtraTight {
  margin: vapor.spacing('extra-tight');
}

.marginTight {
  margin: vapor.spacing('tight');
}

.marginBaseTight {
  margin: vapor.spacing('base-tight')
}

.marginBase {
  margin: vapor.spacing('base')
}

.marginBaseLoose {
  margin: vapor.spacing('base-loose')
}

.marginLoose {
  margin: vapor.spacing('loose');
}

.marginExtraLoose {
  margin: vapor.spacing('extra-loose');
}

.marginExtremeLoose {
  margin: vapor.spacing('extreme-loose');
}

// margin bottom

.marginBottomNone {
  margin-bottom: vapor.spacing('none');
}

.marginBottomExtremeTight {
  margin-bottom: vapor.spacing('extreme-tight');
}

.marginBottomExtraTight {
  margin-bottom: vapor.spacing('extra-tight');
}

.marginBottomTight {
  margin-bottom: vapor.spacing('tight');
}

.marginBottomBaseTight {
  margin-bottom: vapor.spacing('base-tight')
}

.marginBottomBase {
  margin-bottom: vapor.spacing('base')
}

.marginBottomBaseLoose {
  margin-bottom: vapor.spacing('base-loose')
}

.marginBottomLoose {
  margin-bottom: vapor.spacing('loose');
}

.marginBottomExtraLoose {
  margin-bottom: vapor.spacing('extra-loose');
}

.marginBottomExtremeLoose {
  margin-bottom: vapor.spacing('extreme-loose');
}

// margin left

.marginLeftNone {
  margin-left: vapor.spacing('none');
}

.marginLeftExtremeTight {
  margin-left: vapor.spacing('extreme-tight');
}

.marginLeftExtraTight {
  margin-left: vapor.spacing('extra-tight');
}

.marginLeftTight {
  margin-left: vapor.spacing('tight');
}

.marginLeftBaseTight {
  margin-left: vapor.spacing('base-tight')
}

.marginLeftBase {
  margin-left: vapor.spacing('base')
}

.marginLeftBaseLoose {
  margin-left: vapor.spacing('base-loose')
}

.marginLeftLoose {
  margin-left: vapor.spacing('loose');
}

.marginLeftExtraLoose {
  margin-left: vapor.spacing('extra-loose');
}

.marginLeftExtremeLoose {
  margin-left: vapor.spacing('extreme-loose');
}

// margin right

.marginRightNone {
  margin-right: vapor.spacing('none');
}

.marginRightExtremeTight {
  margin-right: vapor.spacing('extreme-tight');
}

.marginRightExtraTight {
  margin-right: vapor.spacing('extra-tight');
}

.marginRightTight {
  margin-right: vapor.spacing('tight');
}

.marginRightBaseTight {
  margin-right: vapor.spacing('base-tight')
}

.marginRightBase {
  margin-right: vapor.spacing('base')
}

.marginRightBaseLoose {
  margin-right: vapor.spacing('base-loose')
}

.marginRightLoose {
  margin-right: vapor.spacing('loose');
}

.marginRightExtraLoose {
  margin-right: vapor.spacing('extra-loose');
}

.marginRightExtremeLoose {
  margin-right: vapor.spacing('extreme-loose');
}

// margin top

.marginTopNone {
  margin-top: vapor.spacing('none');
}

.marginTopExtremeTight {
  margin-top: vapor.spacing('extreme-tight');
}

.marginTopExtraTight {
  margin-top: vapor.spacing('extra-tight');
}

.marginTopTight {
  margin-top: vapor.spacing('tight');
}

.marginTopBaseTight {
  margin-top: vapor.spacing('base-tight')
}

.marginTopBase {
  margin-top: vapor.spacing('base')
}

.marginTopBaseLoose {
  margin-top: vapor.spacing('base-loose')
}

.marginTopLoose {
  margin-top: vapor.spacing('loose');
}

.marginTopExtraLoose {
  margin-top: vapor.spacing('extra-loose');
}

.marginTopExtremeLoose {
  margin-top: vapor.spacing('extreme-loose');
}

// padding

.paddingNone {
  padding: vapor.spacing('none');
}

.paddingExtremeTight {
  padding: vapor.spacing('extreme-tight');
}

.paddingExtraTight {
  padding: vapor.spacing('extra-tight');
}

.paddingTight {
  padding: vapor.spacing('tight');
}

.paddingBaseTight {
  padding: vapor.spacing('base-tight')
}

.paddingBase {
  padding: vapor.spacing('base')
}

.paddingBaseLoose {
  padding: vapor.spacing('base-loose')
}

.paddingLoose {
  padding: vapor.spacing('loose');
}

.paddingExtraLoose {
  padding: vapor.spacing('extra-loose');
}

.paddingExtremeLoose {
  padding: vapor.spacing('extreme-loose');
}

// padding bottom

.paddingBottomNone {
  padding-bottom: vapor.spacing('none');
}

.paddingBottomExtremeTight {
  padding-bottom: vapor.spacing('extreme-tight');
}

.paddingBottomExtraTight {
  padding-bottom: vapor.spacing('extra-tight');
}

.paddingBottomTight {
  padding-bottom: vapor.spacing('tight');
}

.paddingBottomBaseTight {
  padding-bottom: vapor.spacing('base-tight')
}

.paddingBottomBase {
  padding-bottom: vapor.spacing('base')
}

.paddingBottomBaseLoose {
  padding-bottom: vapor.spacing('base-loose')
}

.paddingBottomLoose {
  padding-bottom: vapor.spacing('loose');
}

.paddingBottomExtraLoose {
  padding-bottom: vapor.spacing('extra-loose');
}

.paddingBottomExtremeLoose {
  padding-bottom: vapor.spacing('extreme-loose');
}

// padding left

.paddingLeftNone {
  padding-left: vapor.spacing('none');
}

.paddingLeftExtremeTight {
  padding-left: vapor.spacing('extreme-tight');
}

.paddingLeftExtraTight {
  padding-left: vapor.spacing('extra-tight');
}

.paddingLeftTight {
  padding-left: vapor.spacing('tight');
}

.paddingLeftBaseTight {
  padding-left: vapor.spacing('base-tight')
}

.paddingLeftBase {
  padding-left: vapor.spacing('base')
}

.paddingLeftBaseLoose {
  padding-left: vapor.spacing('base-loose')
}

.paddingLeftLoose {
  padding-left: vapor.spacing('loose');
}

.paddingLeftExtraLoose {
  padding-left: vapor.spacing('extra-loose');
}

.paddingLeftExtremeLoose {
  padding-left: vapor.spacing('extreme-loose');
}

// padding right

.paddingRightNone {
  padding-right: vapor.spacing('none');
}

.paddingRightExtremeTight {
  padding-right: vapor.spacing('extreme-tight');
}

.paddingRightExtraTight {
  padding-right: vapor.spacing('extra-tight');
}

.paddingRightTight {
  padding-right: vapor.spacing('tight');
}

.paddingRightBaseTight {
  padding-right: vapor.spacing('base-tight')
}

.paddingRightBase {
  padding-right: vapor.spacing('base')
}

.paddingRightBaseLoose {
  padding-right: vapor.spacing('base-loose')
}

.paddingRightLoose {
  padding-right: vapor.spacing('loose');
}

.paddingRightExtraLoose {
  padding-right: vapor.spacing('extra-loose');
}

.paddingRightExtremeLoose {
  padding-right: vapor.spacing('extreme-loose');
}

// padding top

.paddingTopNone {
  padding-top: vapor.spacing('none');
}

.paddingTopExtremeTight {
  padding-top: vapor.spacing('extreme-tight');
}

.paddingTopExtraTight {
  padding-top: vapor.spacing('extra-tight');
}

.paddingTopTight {
  padding-top: vapor.spacing('tight');
}

.paddingTopBaseTight {
  padding-top: vapor.spacing('base-tight')
}

.paddingTopBase {
  padding-top: vapor.spacing('base')
}

.paddingTopBaseLoose {
  padding-top: vapor.spacing('base-loose')
}

.paddingTopLoose {
  padding-top: vapor.spacing('loose');
}

.paddingTopExtraLoose {
  padding-top: vapor.spacing('extra-loose');
}

.paddingTopExtremeLoose {
  padding-top: vapor.spacing('extreme-loose');
}
