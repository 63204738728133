@use 'styles/vapor' as vapor;

.ButtonContent {
  display: inline-flex;
  align-items: center;
  text-align: center;
  min-width: vapor.spacing('tightest');
  min-height: vapor.spacing('tightest');
  width: 100%;

  &.justifyCenter {
    justify-content: center;
  }

  &.justifySpaceBetween {
    justify-content: space-between;
  }
}

// Icon

.buttonIcon {
  svg {
    @include vapor.icon-size('small');
  }

  &.iconOnlySizeBase svg {
    @include vapor.icon-size('medium');
  }

  &.iconOnlySizeSmall svg {
    @include vapor.icon-size('small');
  }

  &.iconAlignedLeft {
    margin-right: vapor.spacing('tight');
  }

  &.iconAlignedRight {
    margin-left: vapor.spacing('tight');
  }
}
