@use 'styles/vapor' as vapor;

.ModalWrapper {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: vapor.z-index('modal');
  background-color: vapor.color('mask', 'base-75');
  backdrop-filter: blur(10px);
  box-shadow: vapor.shadow('drop-shadow-12');
  display: flex;
  align-items: center;
  justify-content: center;
  animation: fade-in 0.3s forwards;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
