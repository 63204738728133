@use 'styles/vapor' as vapor;

$local-z-index: 2;

.DateRangePicker {
  .slideDeck {
    display: flex;
    align-items: center;
  }

  .slide {
    padding: vapor.spacing('extra-tight') 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    @include vapor.typography('button', 'base');
  }

  .navigationButton {
    position: absolute;
    z-index: $local-z-index;
  }

  .buttonPrev {
    left: 0;
  }

  .buttonNext {
    right: 0;
  }
}