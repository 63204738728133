@use 'styles/vapor' as vapor;

.instructions {
  padding-bottom: vapor.spacing('tight');
  text-transform: uppercase;
  @include vapor.typography('overline', 'base');
}

.step {
  padding-bottom: vapor.spacing('base-loose');
}

.timestamp {
  padding-bottom: vapor.spacing('tight');
}

.submit {
  margin-top: vapor.spacing('tight');
}
