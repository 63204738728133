@use 'styles/vapor' as vapor;

$footer-height: 64px;
$max-footer-width: 1400px;

.Footer {
  display: block;
  width: 100%;
  height: $footer-height;
  position: fixed;
  bottom: 0;
  overflow: hidden;
  z-index: vapor.z-index('layout');
  background-color: vapor.color('white');
  box-shadow: vapor.shadow('drop-shadow-2');

  @include vapor.respond-to('desktop-big') {
    display: none;
  }
  @include vapor.respond-to('tall-mobile') {
    display: block;
  }

  &.hidden {
    height: 0;
  }

  transition: height 0.5s ease 0s;
}

.content {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  height: 100%;
  width: 100%;
  max-width: $max-footer-width;
  margin: 0 auto;
}
