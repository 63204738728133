@use 'styles/vapor' as vapor;

$invalid-insight-queries: (
  narrowHorizontal:  ( min-width:  286px ),
);

.InvalidInsight {
  container: invalid-insight / inline-size;

  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: vapor.color('white', 'base-75');
  backdrop-filter: blur(5px);
  overflow: hidden;
  border-radius: inherit;

  .content {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    box-sizing: border-box;

    .description {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &.formatVertical {
      flex-direction: column;
      justify-content: center;
      gap: vapor.spacing('base');

      .description {
        align-items: center;
        gap: vapor.spacing('base');
      }
    }

    &.formatHorizontal {
      flex-direction: row;
      justify-content: space-evenly;
      gap: vapor.spacing('none');

      @include vapor.respond-to-container('narrowHorizontal', $invalid-insight-queries) {
        justify-content: center;
        gap: vapor.spacing('base');
      }

      .description {
        align-items: flex-start;
        gap: vapor.spacing('extra-tight');
      }
    }
  
    svg {
      @include vapor.icon-size('jumbo');
    }
  }
}

